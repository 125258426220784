<script>
import RequisitesList from "@/components/RequisitesList.vue";

export default {
  components: {
    RequisitesList,
  },
  computed: {
    requisites() {
      return [
        {
          title: this.$gettext("Nazwa"),
          text: this.$gettext("ADVY PROSTA SPÓŁKA AKCYJNA"),
        },
        {
          title: this.$gettext("Biuro"),
          text: this.$gettext("UL. SZCZYTOWA 26, lokal 15, 20-562, Lublin"),
        },
        {
          title: this.$gettext("Prezes Zarządu"),
          text: this.$gettext("Zakhar Borok"),
        },
        { title: this.$gettext("KRS"), text: this.$gettext("0001018054") },
        { title: this.$gettext("NIP"), text: this.$gettext("№: 7123449391") },
      ];
    },
  },
};
</script>

<template>
  <section class="d-flex flex-column justify-content-center px-2 py-5 gap-2">
    <h1 class="text-center mb-5"><translate>Our requisites</translate></h1>

    <div class="content">
      <RequisitesList :requisites="requisites" />

      <div class="image-wrapper">
        <img src="@/assets/images/requisites.svg" class="image" />
      </div>
    </div>
  </section>
</template>

<style>
.content {
  display: flex;
  justify-content: center;
  column-gap: 100px;
}

.image-wrapper {
  display: flex;
  align-items: center;
}

.image {
  height: 500px;
  width: 300px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .image-wrapper {
    display: none;
  }
}
</style>